import React, {createContext, useContext, useState, ReactNode} from "react";
import {Restaurant} from "../../type/Types";

interface RestaurantIdContextType {
    restaurantId: number | null;
    setRestaurantId: (storeId: number | null) => void;
    restaurant: Restaurant | null;
    setRestaurant: (restaurant: Restaurant | null) => void;
}

const RestaurantIdContext = createContext<RestaurantIdContextType | undefined>(undefined);

export const SelectRestaurantProvider: React.FC<{ children: ReactNode; }> = ({ children }) => {
    const [restaurantId, setRestaurantId] = useState<number | null>(null);
    const [restaurant, setRestaurant] = useState<Restaurant | null>(null);

    return (
        <RestaurantIdContext.Provider value={{restaurantId, setRestaurantId, restaurant, setRestaurant}}>
            {children}
        </RestaurantIdContext.Provider>
    );
};

export const useRestaurantIdContext = () => {
    const context = useContext(RestaurantIdContext);
    if (!context) {
        throw new Error('useStoreContext must be used within a StoreProvider');
    }
    return context;
};
