// BottomSheetContextOpen.tsx
import React, {createContext, ReactNode, useContext, useState} from "react";

interface BottomSheetContextProps {
    bottomSheetOpen: boolean;
    setBottomSheetOpen: (open: boolean) => void;
}

const BottomSheetContext = createContext<BottomSheetContextProps | undefined>(undefined);

export const BottomSheetProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [bottomSheetOpen, setBottomSheetOpen] = useState(true);

    return (
        <BottomSheetContext.Provider value={{ bottomSheetOpen, setBottomSheetOpen }}>
            {children}
        </BottomSheetContext.Provider>
    );
};

export const useBottomSheetContext = () => {
    const context = useContext(BottomSheetContext);
    if (!context) {
        throw new Error("useBottomSheetContext must be used within a BottomSheetProvider");
    }
    return context;
};
