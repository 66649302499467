import {AxiosResponse} from "axios";
import {api} from "./Api";
import {Restaurant, RestaurantList} from "../type/Types";

export const getRestaurantList = (): Promise<AxiosResponse<RestaurantList[]>> => {

    return api.get('/restaurants');
};

export const getRestaurantListByPage = (page: number, size: number): Promise<AxiosResponse<RestaurantList[]>> => {

    return api.get(`/restaurants?page=${page}&size=${size}`);
}

export const getSearchByKeywordRestaurantList = (search: string): Promise<AxiosResponse<RestaurantList[]>> => {

    return api.get(`/restaurants/search?search=${search}`);
};

export const getRestaurant = (id: number): Promise<AxiosResponse<Restaurant>> => {
    return api.get(`/restaurants/restaurant?id=${id}`);
};

export const getSearchByTagRestaurantList = (tagValue: string): Promise<AxiosResponse<RestaurantList[]>> => {
    return api.get(`/restaurants/tag?tagValue=${tagValue}`);
}
