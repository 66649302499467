import React, {useState, useCallback, useMemo, useEffect, useRef} from 'react';
import styled from "styled-components";
import {HiMapPin} from "react-icons/hi2";
import {FcGoogle} from "react-icons/fc";
import {SiYoutube, SiYoutubeshorts} from "react-icons/si";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {useRestaurantIdContext} from "../context/RestaurantIdContext";
import {getRestaurant} from "../../api/RestaurantApi";
import {Restaurant} from "../../type/Types";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const StoreInfo = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 20px;
    padding-bottom: 15px;
    
    @media (max-width: 768px) {
        padding-bottom: 10px;
    }
`

const Positioner = styled.div`
    display: flex;
    flex-direction: row;
`

const PinIcon = styled(HiMapPin)`
    font-size: 18px;
    margin-top: 5px;
    margin-right: 4px;
`

const StoreName = styled.div`
    font-size: 21px;
    font-weight: bold;
`

const StoreDescription = styled.div`
    font-size: 14px;
    margin-top: 10px;
    text-align: left;
    color: #b4b4b4;
`

const GoogleMapButton = styled.a`
    width: 77%;
    height: 24px;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: white;
    color: black;
    border-radius: 8px;
    border: 1px solid #bebebe;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center; 
    justify-content: center; 
    text-align: center;
    cursor: pointer;
`;

const GoogleIcon = styled(FcGoogle)`
    font-size: 18px;
    margin-right: 8px;
`
const LeftButton = styled(IoIosArrowBack)`
    position: absolute;
    top: 50%;
    left: 3px;
    font-size: 25px;
    transform: translateY(-50%);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
    opacity: 0;
`;

const RightButton = styled(IoIosArrowForward)`
    position: absolute;
    top: 50%;
    right: 3px;
    font-size: 25px;
    transform: translateY(-50%);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
    opacity: 0;
`;

const YoutubeCounter = styled.div`
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    z-index: 2;
    padding: 5px 8px;
    border-radius: 4px;
    opacity: 0;
`;

const YoutubeContainer = styled.div`
    width: 88%;
    height: 46%;
    position: relative;
    background: #fff;
    margin-left: 20px;
    border-radius: 8px;
    display: block; /* a 태그로 링크 가능하게 함 */
    overflow: hidden; /* 이미지가 넘치지 않도록 설정 */

    &:hover ${LeftButton}, &:hover ${RightButton}, &:hover ${YoutubeCounter} {
        opacity: 1;
    }

    @media (max-width: 768px) {
        height: 22vh;
        margin-bottom: 5px;
        .swiper-pagination {
            right: 15px;
            top: 8px;
            left: auto;
            width: auto;
            color: #fff;
            font-weight: bold;
            font-size: 12px;
        }
        
    }
`

const YoutubeThumbnail = styled.img`
    width: 100%;
    height: 105%;
    object-fit: cover; /* 썸네일이 컨테이너 크기에 딱 맞게 */
    border-radius: 8px;
    
    @media (max-width: 768px) {
        height: 23vh;
    }
    
`;

const YoutubeShortsThumbnailOverlay = styled.div`
    position: absolute;
    width: 32%;
    height: 100%;
    top: 0px;
    left: 34%;
    background: rgba(0, 0, 0, 0.5);
    
    @media (max-width: 768px) {
        left: 29.5vw;
        width: 29vw;
    }
`

const YoutubeThumbnailOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 8px;

    @media (max-width: 768px) {
    }
`

const YoutubeShortsIcon = styled(SiYoutubeshorts)`
    width: 25px;
    height: 25px;
    position: absolute;
    top: 10px;
    left: 10px;
    color: red;
    z-index: 1;
`;

const YoutubeIcon = styled(SiYoutube)`
    width: 25px;
    height: 25px;
    position: absolute;
    top: 10px;
    left: 10px;
    color: red;
    z-index: 1;
`;

const YoutubeIconBackground = styled.div`
    position: absolute;
    width: 7px;
    height: 7px;
    top: 19px;
    left: 19px;
    background-color: white;
`;

const YoutubeText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 가운데 정렬 */
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: white;
    z-index: 1; /* 텍스트를 이미지 위에 표시 */
    
`;

const SideShopDetail: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { restaurantId, restaurant, setRestaurant } = useRestaurantIdContext()
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // 화면 크기 변경 시 모바일 여부 업데이트
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (restaurantId !== null) {
                try {
                    console.log("SideShopDetail");
                    const restaurant = await getRestaurant(restaurantId);
                    setRestaurant(restaurant.data);
                } catch (error) {
                    console.error("Failed to fetch find restaurant", error);
                }
            }
        };

        fetchData();
    }, [restaurantId]);

    useEffect(() => {
        setCurrentIndex(0);
    }, [restaurant]);

    const maxIndex = useMemo(() => (restaurant?.youtubeVideos ? restaurant.youtubeVideos.length - 1 : 0), [restaurant]);

    const onSlide = useCallback(
        (direction: "left" | "right") => {
            setCurrentIndex((prevIndex) => {
                if (direction === "left") {
                    return prevIndex === 0 ? maxIndex : prevIndex - 1;
                } else {
                    return prevIndex === maxIndex ? 0 : prevIndex + 1;
                }
            });
        },
        [maxIndex]
    );

    if (!restaurant) {
        return (
            <StoreInfo>
                <StoreDescription>가게 정보를 찾을 수 없습니다.</StoreDescription>
                <GoogleMapButton><GoogleIcon />구글 맵으로 더보기</GoogleMapButton>
            </StoreInfo>
        );
    }

    return (
        <>
            <StoreInfo>
                <Positioner>
                    <PinIcon/>
                    <StoreName>
                        {restaurant.name}
                    </StoreName>
                </Positioner>
                <StoreDescription>
                    {restaurant.description}
                </StoreDescription>
                <GoogleMapButton href={restaurant.googleUrl} target="_blank" rel="noopener noreferrer">
                    <GoogleIcon />구글 맵으로 더보기
                </GoogleMapButton>
            </StoreInfo>
            {restaurant.youtubeVideos && restaurant.youtubeVideos.length > 0 && (
                <YoutubeContainer className="YoutubeContainer">
                    {isMobile ? (
                        <>
                            <Swiper
                                pagination={{
                                    type: 'fraction'
                                }}
                                spaceBetween={10}
                                slidesPerView={1}
                                modules={[Pagination]}
                            >
                                {restaurant.youtubeVideos.map((video, index) => (
                                    <SwiperSlide key={video.youtubeUrl}>
                                        <a href={video.youtubeUrl} target="_blank" rel="noopener noreferrer">
                                            <YoutubeThumbnail className="YoutubeThumnail"
                                                              src={video.youtubeImageUrl}
                                                              alt={`${restaurant.name} 유튜브 썸네일`}/>
                                            {video.isShort ? (
                                                <YoutubeThumbnailOverlay />
                                            ) : (
                                                <YoutubeThumbnailOverlay />
                                            )}
                                            <YoutubeText>Youtube로 리뷰<br/>확인하기</YoutubeText>
                                        </a>
                                        {video.isShort ? (
                                            <YoutubeShortsIcon/>
                                        ) : (
                                            <YoutubeIcon/>
                                        )}
                                        <YoutubeIconBackground/>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </>
                    ) : (
                        <>
                            <YoutubeCounter>
                                {currentIndex + 1} / {restaurant.youtubeVideos.length}
                            </YoutubeCounter>
                            {restaurant.youtubeVideos.map((video, index) => (
                                <div key={video.youtubeUrl} style={{ display: index === currentIndex ? 'block' : 'none' }}>
                                    <a href={video.youtubeUrl} target="_blank" rel="noopener noreferrer">
                                        <YoutubeThumbnail
                                            src={video.youtubeImageUrl}
                                            alt={`${restaurant.name} 유튜브 썸네일`}
                                        />
                                        {video.isShort ? (
                                            <YoutubeShortsThumbnailOverlay />
                                        ) : (
                                            <YoutubeThumbnailOverlay />
                                        )}
                                        <YoutubeText>Youtube로 리뷰<br />확인하기</YoutubeText>
                                    </a>
                                    {video.isShort ? (
                                        <YoutubeShortsIcon />
                                    ) : (
                                        <YoutubeIcon />
                                    )}
                                    <YoutubeIconBackground />
                                </div>
                            ))}
                            <LeftButton onClick={() => onSlide("left")} />
                            <RightButton onClick={() => onSlide("right")} />
                        </>
                    )}

                </YoutubeContainer>
            )}
        </>
    );
};

export default React.memo(SideShopDetail);


