import React, {createContext, useCallback, useContext, useState} from "react";
import {useRestaurantListContext} from "./RestaurantListContext";
import {filterStores} from "../util/searchUtil";
import {all} from "axios";

interface SearchContextType {
    input: string;
    setInput: React.Dispatch<React.SetStateAction<string>>;
    onHome: () => void
    onSearch: (event: React.KeyboardEvent<HTMLInputElement>) => void
    onClear: () => void
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const SearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [input, setInput] = useState<string>("");
    const {restaurantList, setRestaurantList, allRestaurantList} = useRestaurantListContext();

    const onHome = useCallback(() => {
        setRestaurantList(allRestaurantList);
        setInput("");
    }, [setRestaurantList, allRestaurantList])

    const onSearch = useCallback(async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const filterRestaurantList = await filterStores(input, false);
            setRestaurantList(filterRestaurantList);
        }
    }, [input, filterStores, setRestaurantList]);

    const onClear = useCallback(() => {
        setInput("");
        setRestaurantList(allRestaurantList);
    }, [setRestaurantList, allRestaurantList]);

    return (
        <SearchContext.Provider value={{ input, setInput, onHome, onSearch, onClear }}>
            {children}
        </SearchContext.Provider>
    );
};

export const useSearchContext = () => {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error("useSearchContext must be used within a SearchProvider");
    }
    return context;
};
