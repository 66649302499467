import {useRestaurantListContext} from "../context/RestaurantListContext";
import {getSearchByKeywordRestaurantList, getSearchByTagRestaurantList} from "../../api/RestaurantApi";

export const filterStores = async (searchTerm: string, isTag: boolean) => {
    try {
        const response = isTag ? await getSearchByTagRestaurantList(searchTerm)
            : await getSearchByKeywordRestaurantList(searchTerm);
        return response.data;
    } catch (error) {
        console.error("Failed to fetch filtered restaurant list", error);
        return [];
    }
};


