import React, {useState, useRef, CSSProperties, useMemo, useCallback, useEffect} from 'react';
import '../../styles.css'
import styled, {css} from 'styled-components';
import {AdvancedMarker, APIProvider, Map, Pin, InfoWindow, MapCameraChangedEvent} from '@vis.gl/react-google-maps';
import {useRestaurantIdContext} from "../context/RestaurantIdContext";
import {useRestaurantListContext} from "../context/RestaurantListContext";
import {getRestaurant} from "../../api/RestaurantApi";
import { FaMapMarkerAlt } from "react-icons/fa";
import {useBottomSheetContext} from "../context/BottomSheetContext";

const MapContainer = styled.div`
    width: calc(100% - 29rem);
`

const Marker = styled(FaMapMarkerAlt)<{ $isActive: boolean }>`
    color: ${({ $isActive }) => ($isActive ? '#FF4646' : '#0A82FF')};
    background: transparent;  // 배경을 완전히 투명하게 설정
    width: 32px;
    height: 32px;
    display: flex;  // 아이콘을 정확히 가운데 정렬하는 데 도움
    align-items: center;
    justify-content: center;
    padding: 0;  // 패딩을 없애서 블록 느낌 제거
    box-shadow: none;  // 혹시 그림자나 테두리가 있다면 제거
    z-index: 1;

    transition: transform 0.3s ease;  // 애니메이션 추가

    ${({ $isActive }) =>
            $isActive &&
            css`
            transform: scale(1.5);  // 활성화된 마커 크기 증가
        `}
`

const MarkerName = styled.div`
    position: absolute;
    top: 36px;  // 마커 바로 아래에 위치하도록 설정
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    font-weight: bold;
    color: #333;
    background: rgba(255, 255, 255, 0.8);  // 가독성을 위해 약간의 배경 추가
    padding: 2px 6px;
    border-radius: 4px;
    white-space: nowrap;  // 이름이 길어도 줄바꿈되지 않도록 설정
    z-index: 2;
`;

const InfoWindowContent = styled.div`
    padding: 0;
    margin-left: 6px;
    margin-bottom: 8px;
`

const InfoWindowStoreImage = styled.img`
    width: 100%;
    height: 100px;
    display: block;
    padding: 0;
    margin-bottom: 8px;
`

const InfoWindowStoreName = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 4px;
`

const InfoWindowStoreDesc = styled.div`
    color: #666;
    font-size: 12px;
`

// Google Map 스타일과 크기를 정의합니다.
const containerStyle = {
    width: 'calc(100% - 29rem)',
    left: '29rem',
    height: '100vh' // 필요에 맞게 높이를 설정하세요.
};

const mobileContainerStyle = {
    width: '100%',
    height: '100vh',
    bottom:  '10vh'
}

// 지도 중심 위치 설정 (위도, 경도)
const defaultCenter = {
    lat: 33.5903547,
    lng: 130.4017155
};

const INITIAL_CAMERA_PROPS = {
    center: {lat: 33.5903547, lng: 130.4017155},
    zoom: 15,
    heading: 0,
    tilt: 0
};

const AdvancedMarkerStyle: CSSProperties = {
    cursor: 'pointer'
}

const infoWindowStyle: CSSProperties = {
    width: '270px'
}

const Maps: React.FC = () => {
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

    const { restaurantList } = useRestaurantListContext();
    const { restaurantId, setRestaurantId, restaurant } = useRestaurantIdContext();

    const [cameraProps, setCameraProps] = useState(INITIAL_CAMERA_PROPS);
    const [openInfoWindowId, setOpenInfoWindowId] = useState<number | null>(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const { setBottomSheetOpen } = useBottomSheetContext();
    // 화면 크기 변경 시 모바일 여부 업데이트
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const markerRefs = useRef<(google.maps.marker.AdvancedMarkerElement | null)[]>([]);

    const memoRestaurantList = useMemo(() => restaurantList, [restaurantList]);

    const onMapClick = useCallback(() => {
        if (restaurantId !== null) {
            setRestaurantId(null);
            setBottomSheetOpen(true);
        }
    }, [restaurantId, setRestaurantId, setBottomSheetOpen])

    const onMarkerClick = useCallback((restaurantId: number) => {
        setRestaurantId(restaurantId);
    }, [setRestaurantId]);

    const onMouseEnter = useCallback((restaurantId: number) => {
        setOpenInfoWindowId(restaurantId);
    }, [setOpenInfoWindowId]);

    const onMouseLeave = useCallback(() => {
        setOpenInfoWindowId(null);
    }, [setOpenInfoWindowId]);

    const handleCameraChange = useCallback((ev: MapCameraChangedEvent) => {
        setCameraProps(ev.detail)
    }, []);

    const setCenter = useCallback((location: {lat: number, lng: number}) => {
        setCameraProps(prevState => ({
            ...prevState,
            center: location
        }));
    }, [])

    useEffect(() => {
        if (restaurant !== null) {
            const location = restaurant.location;
            setCenter({lat: location.lat, lng: location.lng});
        }
    }, [restaurant, setCenter]);


    return (
        <APIProvider apiKey={googleMapsApiKey}>
            <Map
                mapId={"49ae42fed52588c3"}
                style={isMobile ? (mobileContainerStyle) : (containerStyle)}
                defaultCenter={defaultCenter}
                defaultZoom={13}
                gestureHandling={'greedy'}
                clickableIcons={false}
                disableDefaultUI={true}
                {...cameraProps}
                onCameraChanged={(e) => handleCameraChange(e)}
                onClick={() => {
                    if (isMobile) {
                        onMapClick(); // 모바일 환경에서만 onMapClick 실행
                    }
                }}
            >
                {memoRestaurantList.map((restaurant, index) => (
                    <React.Fragment key={restaurant.id}>
                        <AdvancedMarker
                            key={restaurant.id}
                            position={{lat: restaurant.location.lat, lng: restaurant.location.lng}}
                            ref={(ref) => {
                                markerRefs.current[index] = ref;
                            }}
                            style={AdvancedMarkerStyle}
                            onClick={() => onMarkerClick(restaurant.id)}
                            onMouseEnter={() => onMouseEnter(restaurant.id)}
                            onMouseLeave={onMouseLeave}
                        >
                            <Marker $isActive={restaurantId === restaurant.id}/>
                            <MarkerName>{restaurant.name}</MarkerName>
                        </AdvancedMarker>
                        {!isMobile && (openInfoWindowId === restaurant.id && (
                            <InfoWindow
                                anchor={markerRefs.current[index]}
                                style={infoWindowStyle}
                            >
                                <InfoWindowStoreImage src={restaurant.imageUrl} alt={restaurant.name}/>
                                <InfoWindowContent>
                                    <InfoWindowStoreName>{restaurant.name}</InfoWindowStoreName>
                                    <InfoWindowStoreDesc>{restaurant.description}</InfoWindowStoreDesc>
                                </InfoWindowContent>
                            </InfoWindow>
                        ))}
                    </React.Fragment>
                ))}
            </Map>
        </APIProvider>
    );
};

export default Maps;
