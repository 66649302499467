import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {IoSearchOutline} from "react-icons/io5";
import {MdClose} from "react-icons/md";
import { RiErrorWarningLine } from "react-icons/ri";

//JSON 데이터 가져오기
// import storeData from '../../data/shopData.json';

import SideDetail from "../sideDetail/SideDetail";
import {useRestaurantIdContext} from "../context/RestaurantIdContext";
import {useRestaurantListContext} from "../context/RestaurantListContext";
import {
    getRestaurantList, getRestaurantListByPage,
    getSearchByKeywordRestaurantList,
    getSearchByTagRestaurantList
} from "../../api/RestaurantApi";
import {useSearchContext} from "../context/SearchContext";
import {filterStores} from "../util/searchUtil";
import {useBottomSheetContext} from "../context/BottomSheetContext";
import {RestaurantList} from "../../type/Types";

const Positioner = styled.div`
    display: flex;
    align-items: center; /* 세로 가운데 정렬 */
    width: 100%;
`;

const Logo = styled.div`
    font-size: 24px;
    font-weight: bold;
    color: #50D6FF;
    margin-right: 8px;
    cursor: pointer;
`;

const InputPositioner = styled.div`
    position: relative;
    display: flex;
    justify-content: center; /* 가로 가운데 정렬 */
    width: 100%;
`

const Input = styled.input`
    padding: 12px 12px 12px 45px;
    margin-top: 8px;
    border-radius: 4px;
    border: 2px solid #50D6FF;
    width: 95%;
    outline: none;
    font-size: 18px;
    box-sizing: border-box;

    &:focus::placeholder {
        color: #ccc; /* focus 되었을 때 연한 색으로 변경 */
    }
`;

const SearchIcon = styled(IoSearchOutline)`
    position: absolute;
    left: 25px;
    top: calc(50% + 3px);
    transform: translateY(-50%);
    color: #50D6FF;
    font-size: 20px;
`

const ClearIcon = styled(MdClose)`
    position: absolute;
    right: 25px;
    top: calc(50% + 3px);
    transform: translateY(-50%);
    color: #50D6FF;
    font-size: 20px;
    cursor: pointer;

    &:hover {
        color: #666;
    }
`;

const StoreList = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;  
    gap: 16px;
    
    @media (max-width: 768px) {
        width: calc(100% - 4px);
    }
`;

const StoreItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #d2d2d2;
    //border: 1px solid #50D6FF;
    //border-radius: 4px;
    cursor: pointer;
    
    @media (max-width: 768px) {
        flex-direction: row;
        align-items: flex-start;
        height: auto;
        width: 100%;
    }
`;

const StoreImage = styled.img`
    width: 100%;
    height: 180px;
    border-radius: 8px;
    object-fit: cover;
    margin-right: 10px;
    
    @media (max-width: 768px) {
        width: 29%;
        height: 20vh;
    }
`;

const StoreDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    
    @media (max-width: 768px) {
        position: relative;
        width: 70%;
        height: 20vh;
        margin-left: 10px;
        justify-content: flex-end;
    }
`;

const StoreInfo = styled.div`
    display: flex;
    align-items: center;
    margin-left: 5px;
    
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

const StoreName = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-top: 12px;
    margin-right: 8px;
    text-align: left;
    
    @media (max-width: 768px) {
        margin-top: 0px;
    }
`;

const StoreDescription = styled.div`
    margin-top: 18px;
    font-size: 12px;
    color: #666;
    flex-shrink: 0;

    @media (max-width: 768px) {
        margin-top: 4px;
    }
`;

const StoreDetail = styled.div`
    margin-top: 15px;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    
`

const BottomPositioner = styled.div`

    @media (max-width: 768px) {
        align-self: flex-start; /* 모바일에서 좌측 정렬 */
        margin-top: auto; /* 하단으로 이동 */
    }
`

const TagList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    
`;

const TagItem = styled.div`
    //background-color: #e0f7ff;
    color: #007bff;
    font-size: 12px;
    padding: 4px 4px;
    border-radius: 12px;
    margin-right: 3px;
    margin-bottom: 4px;
    cursor: pointer;
`;

const YoutubeProfile = styled.div`
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: 768px) {
        align-self: flex-start; /* 모바일에서 좌측 정렬 */
        margin-top: auto; /* 하단으로 이동 */
    }
`;

const ProfileImage = styled.img<{ $index : number }>`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid white; /* 이미지 사이의 구분을 위한 흰색 테두리 */
    position: relative;
    z-index: ${({ $index }) => 10 - $index};
    margin-left: ${({ $index }) => ($index === 0 ? '0' : '-25px')};
`;

const ProfileNames = styled.div`
    font-size: 14px;
    color: #3c3c3c;
    margin-left: 6px;
    margin-right: 2px;
    padding-right: 8px;
`;

const BoldText = styled.span`
    font-weight: bold;
`;

const NoResultMessage = styled.div`
    display: flex;
    flex-direction: column;  /* 아이콘과 텍스트가 위아래로 정렬되도록 */
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 18px;
    font-weight: bold;
    color: rgba(102, 102, 102, 0.6);
    text-align: center;
    gap: 8px; /* 아이콘과 텍스트 사이 간격 */
    margin-top: 100px;
    
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 60px;
    }
`;

const WarningIcon = styled(RiErrorWarningLine)`
    font-size: 72px; /* 아이콘 크기 */
    color: rgba(102, 102, 102, 0.6);
`;

const SideShop: React.FC = () => {

    const {input, setInput,onHome, onSearch, onClear} = useSearchContext();

    const [page, setPage] = useState(0);
    const [hasNext, setHasNext] = useState(true);
    const observer = useRef<IntersectionObserver | null>(null);

    const { restaurantList, setRestaurantList, allRestaurantList, setAllRestaurantList } = useRestaurantListContext();

    const { restaurantId, setRestaurantId } = useRestaurantIdContext();
    const { setBottomSheetOpen } = useBottomSheetContext();

    // 모바일
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // 화면 크기 변경 시 모바일 여부 업데이트
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    /*
    // Paging해서 가져오기
    const fetchRestaurantList = useCallback(async (pageNumber: number) => {
        try {
            const restaurantList = await getRestaurantListByPage(pageNumber, 1);
            const newRestaurantList = restaurantList.data.content;
            setAllRestaurantList((prevList: RestaurantList[]) => [...prevList, ...newRestaurantList]);
            setRestaurantList((prevList: RestaurantList[])=> [...prevList, ...newRestaurantList]);
            setHasNext(restaurantList.data.hasNext);
        } catch (error) {
            console.error("Failed to fetch restaurant list", error);
        }
    }, [setRestaurantList])

    const lastRestaurantRef = useCallback((node: HTMLElement | null) => {
        if(observer.current)
            observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasNext) {
                setPage(prevPage => prevPage + 1);
            }
        });
        if (node)
            observer.current?.observe(node);
    }, [hasNext])

    useEffect(() => {
        fetchRestaurantList(page);
    }, [fetchRestaurantList, page]);
    */

    useEffect(() => {
        const fetchData = async () => {
            try {
                const restaurantList = await getRestaurantList();
                setAllRestaurantList(restaurantList.data);
                setRestaurantList(restaurantList.data);
            } catch (error) {
                console.error("Failed to fetch data", error);
                window.alert(`데이터를 불러오는 데 실패했습니다. ${error}`);
            }
        };
        fetchData();
    }, []);

    const onStoreClick = useCallback((restaurantId: number) => {
        setRestaurantId(restaurantId);
        setBottomSheetOpen(false);
    },[setRestaurantId]);

    const onTagClick = useCallback(async (tag : string) => {
        setInput(tag);
        const filterRestaurantList = await filterStores(tag, true);
        setRestaurantList(filterRestaurantList);
    }, [filterStores, setRestaurantList]);

    return (
        <>
            {!isMobile && (
                <>
                    <Positioner>
                        <Logo onClick={onHome}>HUKU</Logo>  {/* 로고가 검색창 왼쪽에 위치 */}
                        <InputPositioner>
                            <Input
                                type={'text'}
                                placeholder={"가게명 또는 태그로 검색"}
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                onKeyDown={onSearch}
                            />
                            <SearchIcon />
                            {input && <ClearIcon onClick={onClear} />}
                        </InputPositioner>
                    </Positioner>
                    <br />
                </>
            )}
            <StoreList className={"storeList"}>
                {restaurantList.length > 0 ? (
                    restaurantList.map((restaurant, index) => (
                        <StoreItem key={restaurant.id} onClick={() => onStoreClick(restaurant.id)}
                                   //ref={restaurantList.length === index + 1 ? lastRestaurantRef : null}
                        >
                            <StoreImage src={restaurant.imageUrl} alt={restaurant.name}/>
                            <StoreDetails>
                                <StoreInfo>
                                    <StoreName>{restaurant.name}</StoreName>
                                    <StoreDescription>{restaurant.description}</StoreDescription>
                                </StoreInfo>
                                {restaurant.detail && !isMobile &&(
                                    <StoreDetail>{restaurant.detail}</StoreDetail>
                                )}
                                <BottomPositioner>
                                    {restaurant.restaurantTags && (
                                        <TagList>
                                            {restaurant.restaurantTags.slice(0, 5).map((tag, index) => (
                                                <TagItem key={index} onClick={(e) => {
                                                    e.stopPropagation(); // 이벤트 전파 방지
                                                    onTagClick(tag.tagValue);
                                                }}>
                                                    #{tag.tagValue}
                                                </TagItem>
                                            ))}
                                        </TagList>
                                    )}
                                    <YoutubeProfile>
                                        {restaurant.restaurantYoutubers && restaurant.restaurantYoutubers.slice(0, 3).map((youtuber, index) => (
                                            <ProfileImage $index={index} key={index} src={youtuber.youtuberProfile}
                                                          alt="YouTube Profile"/>
                                        ))}
                                        {restaurant.restaurantYoutubers && restaurant.restaurantYoutubers.length === 1 ? (
                                            <ProfileNames>
                                                <BoldText>{restaurant.restaurantYoutubers[0].youtuberName}</BoldText>님이
                                                다녀갔습니다.
                                            </ProfileNames>
                                        ) : (
                                            <ProfileNames>
                                                <BoldText>{restaurant.restaurantYoutubers[0].youtuberName}</BoldText>님
                                                외 <BoldText>여러 명</BoldText>이 다녀갔습니다.
                                            </ProfileNames>
                                        )}
                                    </YoutubeProfile>
                                </BottomPositioner>
                            </StoreDetails>
                        </StoreItem>
                    ))
                ) : (
                    <NoResultMessage>
                        <WarningIcon />
                        검색 결과가 없습니다.
                    </NoResultMessage>
                )}
            </StoreList>
            {restaurantId && <SideDetail onClose={() => {
                setRestaurantId(null);
                setBottomSheetOpen(true);
            }} />}
        </>
    );
}

export default SideShop;
