import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import { IoIosClose } from "react-icons/io";
import SideShopDetail from "./SideShopDetail";
import {useRestaurantIdContext} from "../context/RestaurantIdContext";
import {useBottomSheetContext} from "../context/BottomSheetContext";

const DetailContainer = styled.div`
    
    @media (max-width: 768px) {
        position: fixed;
        background: #fff;
        bottom: 0;
        width: 100%;
        height: auto;
        border-radius: 24px 24px 0px 0px;
        z-index: 20;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
`;

const CloseButton = styled(IoIosClose)`
    
    @media (max-width: 768px) {
        position: absolute;
        font-size: 2px;
        top: -38px;
        right: 5px;
        color: #3c3c3c;
        width: 32px;
        height: 32px;
        border: none;
        border-radius: 8px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
`;

const MobileSideDetail: React.FC = () => {

    const { restaurantId , setRestaurantId } = useRestaurantIdContext();
    const { setBottomSheetOpen } = useBottomSheetContext();
    // 모바일
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // 화면 크기 변경 시 모바일 여부 업데이트
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const onClose = useCallback(() => {
        setRestaurantId(null);
        setBottomSheetOpen(true);
    }, [setRestaurantId, setBottomSheetOpen]);

    return (
        <>
            {restaurantId && isMobile && (
                <DetailContainer>
                    <CloseButton onClick={onClose}></CloseButton>
                    <SideShopDetail />
                </DetailContainer>
            )}
        </>
    );
}

export default MobileSideDetail;
