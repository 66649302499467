import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { BottomSheet } from "react-spring-bottom-sheet";
import SideShop from "./SideShop";
import {IoSearchOutline} from "react-icons/io5";
import {MdClose} from "react-icons/md";
import {useSearchContext} from "../context/SearchContext";

import 'react-spring-bottom-sheet/dist/style.css'
import {useBottomSheetContext} from "../context/BottomSheetContext";
import {useRestaurantIdContext} from "../context/RestaurantIdContext";

const Positioner = styled.div`
    display: flex;
    align-items: center;
    width: 90%;
    font-size: 24px;
    font-weight: bold;
    color: #50D6FF;
    margin-right: 8px;
    cursor: pointer;
    position: absolute;
    top: 5%;
    left: 5%;
    z-index: 10;
    height: 3%;
`;

const Logo = styled.div`
    font-size: 24px;
    font-weight: bold;
    color: #0A82FF;  
    margin-right: 8px;
    cursor: pointer;
    background: none;
`;

const SearchContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 85%;
    height: 100%;
`;

const SearchInput = styled.input`
    font-size: 1rem;
    padding: 0.5rem 2rem 0.5rem 2.5rem;  // 아이콘 위치 확보를 위해 좌우 padding 조정
    border: 1px solid #0A82FF;
    border-radius: 4px;
    outline: none;
    width: 100%;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    height: 100%;
    
    &:focus {
        border-color: #0000FF;
    }
    
    @media (max-width: 768px) {
    }
`;

const SearchIcon = styled(IoSearchOutline)`
    position: absolute;
    left: 0.5rem;
    color: #0A82FF;
`;

const ClearIcon = styled(MdClose)`
    position: absolute;
    right: 0.5rem;
    color: #0A82FF;
    cursor: pointer;
`;



const Sidebar = styled.div<{ height: string }>`
    width: 29rem;
    border: none;
    background-color: #fff;
    height: calc(100vh - 32px);
    position: absolute;
    left: 0;
    transition: left 0.3s ease;
    padding: 1rem;
    box-shadow: 1px 0 3px #50D6FF;
    z-index: 1;
    overflow-y: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #50D6FF;
        border-radius: 4px;
    }

    max-height: 100vh;

    @media (max-width: 768px) {
        width: calc(100% - 32px);
        max-width: 100vw;
        height: ${(props) => props.height};
        position: fixed;
        bottom: 0;
        left: 0;
        box-shadow: none;
        max-height: none;
        border-top: 1px solid #50D6FF;
        z-index: 10;
    }
`;

const Side: React.FC = () => {
    const [height, setHeight] = useState("30%");  // 초기 높이
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);  // 모바일 화면 여부
    const {input, setInput,onHome, onSearch, onClear } = useSearchContext();
    const { restaurantId, setRestaurantId } = useRestaurantIdContext();
    const { bottomSheetOpen, setBottomSheetOpen } = useBottomSheetContext();
    // 화면 크기 변경 시 모바일 여부 업데이트
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (restaurantId !== null) {
                setRestaurantId(null); // restaurantId 초기화
                setBottomSheetOpen(true); // BottomSheet 열기
            }

            e.currentTarget.blur(); // 키패드 닫기
            await new Promise((resolve) => setTimeout(resolve, 0)); // 상태 변경 반영 후 검색 실행
            onSearch(e); // 검색 실행
        }
    };

    return (
        <>
            {isMobile && (
                <Positioner>
                    <Logo onClick={onHome}>HUKU</Logo>
                    <SearchContainer>
                        <SearchIcon />
                        <SearchInput
                            placeholder="가게명 또는 태그로 검색"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={handleKeyDown}
                            inputMode={"search"}
                        />
                        {input && <ClearIcon onClick={onClear}/>}
                    </SearchContainer>
                </Positioner>
            )}
            {isMobile ? (
                <BottomSheet
                open={bottomSheetOpen}
                blocking={false}
                snapPoints={({ maxHeight }) => [maxHeight * 0.3, maxHeight * 0.8]}
                >
                    <SideShop />
                </BottomSheet>
            ) : (
                <Sidebar height={height}>
                    <SideShop/>
                </Sidebar>
            )}
        </>
    );
};

export default Side;
