import React from 'react';
import './App.css';
import Side from "./components/side/Side";
import Maps from "./components/map/Maps";
import {SelectRestaurantProvider} from "./components/context/RestaurantIdContext";
import {RestaurantListProvider} from "./components/context/RestaurantListContext";
import {SearchProvider} from "./components/context/SearchContext";
import {BottomSheetProvider} from "./components/context/BottomSheetContext";
import MobileSideDetail from "./components/sideDetail/MobileSideDetail";

function App() {
  return (
    <>
        <SelectRestaurantProvider>
            <RestaurantListProvider>
                <SearchProvider>
                    <BottomSheetProvider>
                        <Side />
                        <MobileSideDetail />
                        <Maps />
                    </BottomSheetProvider>
                </SearchProvider>
            </RestaurantListProvider>
        </SelectRestaurantProvider>
    </>
  );
}

export default App;
