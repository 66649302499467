import React, {createContext, useContext, useState, ReactNode} from 'react';
import storeData from '../../data/shopData.json';
import {RestaurantList} from "../../type/Types";

interface RestaurantListContextType {
    restaurantList: RestaurantList[];
    setRestaurantList: React.Dispatch<React.SetStateAction<RestaurantList[]>>;
    allRestaurantList: RestaurantList[];
    setAllRestaurantList: React.Dispatch<React.SetStateAction<RestaurantList[]>>;
}

const RestaurantListContext = createContext<RestaurantListContextType | undefined>(undefined);

export const RestaurantListProvider: React.FC<{ children:ReactNode }> = ({ children }) => {
    const[restaurantList, setRestaurantList] = useState<RestaurantList[]>([]);
    const[allRestaurantList, setAllRestaurantList] = useState<RestaurantList[]>([]);

    return (
        <RestaurantListContext.Provider value={{ restaurantList, setRestaurantList, allRestaurantList, setAllRestaurantList }}>
            {children}
        </RestaurantListContext.Provider>
    )
}

export const useRestaurantListContext = () => {
    const context = useContext(RestaurantListContext);
    if (!context) {
        throw new Error('useRestaurantListContext는 RestaurantListProvider 안에서 사용해야 합니다.');
    }
    return context;
};
