import React, { useState } from "react";
import styled from "styled-components";
import { MdClose } from "react-icons/md"
import SideShopDetail from "./SideShopDetail";

const DetailContainer = styled.div`
    position: fixed;
    background: #fff;
    bottom: 16px;
    left: 32rem;
    width: 24rem;
    height: 20rem;
    border-radius: 16px;
    z-index: 20;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    
`

const CloseButton = styled.button`
    position: absolute;
    font-size: 30px;
    top: -38px;
    right: -0px;
    color: #3c3c3c;
    width: 32px;
    height: 32px;
    border: none; 
    border-radius: 8px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
`;

interface SideDetailProps {
    onClose: () => void;
}

const SideDetail: React.FC<SideDetailProps> = ({onClose}) => {

    return (

        <DetailContainer>
            <CloseButton onClick={onClose}><MdClose/></CloseButton>
            <SideShopDetail/>
        </DetailContainer>
    );
};

export default SideDetail;
